import React from 'react'

import './../styles/pageSpecific/services.scss'
import GeneralPageLayout from '../components/Layouts/GeneralPage'
import MainTextBlock from '../components/UI/MainTextBlock/MainTextBlock'
import SectionContainer from '../components/UI/Containers/SectionContainer'
import SectionHeader from '../components/UI/SectionHeader/SectionHeader'
import SplitTwoToneContainer from './../components/UI/Containers/SplitTwoToneContainer'
import CapabilityGraphic from '../components/UI/Capabilities/CapabilityGraphic/CapabilityGraphic'

import ExpertCapabilities from '../components/UI/Capabilities/ExpertCapabilities/ExpertCapabilities'
import ServiceIcon from './../images/icons/capabilities/Training.inline.svg'
import TrainingGraphic from './../images/capabilities/Training.png'

const TrainingPage = () => {
  const pageInfo = {
    pageType: 'interior',
    title1: '',
    title2: 'Training',
    breadcrumbs: ['Capabilities'],
    ctaButton: false,
    ctaButtonData: {
      text: '',
      url: '',
    },
  }

  return (
    <GeneralPageLayout pageData={pageInfo}>
      <div className="content">
        {/* Capabilities Video */}
        <SectionContainer
          id="serviceVideo"
          color="white"
          type="pressedEdgeRight"
          classes={['inlineChildren_half', 'capabilitySection']}
        >
          <section>
            <SectionHeader color="navy" alignment="left" size="med">
              Producing Long Term <span>and Effective Strategies</span>
            </SectionHeader>
            <MainTextBlock>
              <p>
                We design and develop instructional training solutions to exceed
                our customer’s requirements and implement test methods to ensure
                effectiveness. Our training professionals effortlessly blend
                instructor-led schoolhouse training with self-paced virtual
                training to produce the exact solution needed.
              </p>
            </MainTextBlock>
          </section>
          <CapabilityGraphic
            src={TrainingGraphic}
            title="Computer security with locked display"
          />
        </SectionContainer>

        {/* Why Us */}
        <SplitTwoToneContainer id="" classes={['hugMiddleLine']}>
          <section>
            <h3>
              <span>
                Our specialties include the complete design, development,
                implementation, &amp; evaluation of training systems, products,
                &amp; materials.{' '}
              </span>
            </h3>
          </section>
          <section>
            <h3>
              We tailor training solutions to maximize training value per dollar
              invested &amp; provide cradle-to-grave, customized products &amp;
              services to the DoD.
            </h3>
          </section>
        </SplitTwoToneContainer>

        {/* Expert Capabilities */}
        <SectionContainer id="" color="white" type="pressedEdgeLeft">
          <ExpertCapabilities
            serviceName="Training"
            ServiceIcon={ServiceIcon}
          />
        </SectionContainer>
      </div>
    </GeneralPageLayout>
  )
}

export default TrainingPage
